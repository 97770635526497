.stakes {
    background: #f9f9f9;
    padding: 50px;
    height: 100vh;
}

.stakes-header {
    padding-top: 40px;
    width: 100%;
    margin-left: 5px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    color: rgb(9, 6, 98);
    border-bottom: 1px solid rgb(9, 6, 98);
}

.stakes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
    text-align: left;
    margin-left: 0;
    
}


.stakes-list-items {
    padding: 20px;
    padding-top: 0px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 4fr;
    line-height: 1.6;
    font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    position: relative;
}

.fa-circle-xmark {
    font-size: 1.4;
    color: rgb(173, 7, 7);
    align-self: center;
    
}



@media screen and (max-width: 960px) {
    .stakes-list-left {
        margin-left: 5%;
        font-size: 18px;
        color: rgb(8, 6, 56);
        text-align: left;
    }
    .stakes-list-right {
        margin-left: 5%;
        font-size: 18px;
        color: rgb(8, 6, 56);
    }
    .stakes-list-items {
        font-size: 1rem;
        line-height: 2;
    }
}

@media screen and (max-width: 3000px) {
    .stakes-list-left {
        margin-left: 30%;
        font-size: 20px;
        color: rgb(8, 6, 56);
    }
    .stakes-list-right {
        margin-left: 30%;
        font-size: 20px;
        color: rgb(8, 6, 56);
    }
}

@media screen and (max-width: 768px) {
    .stakes {
        height: 90vh;
    }
    .stakes-list-left {
        margin-left: 5%;
        font-size: 18px;
        color: rgb(8, 6, 56);
    }
    .stakes-list-right {
        margin-left: 5%;
        font-size: 18px;
        color: rgb(8, 6, 56);
    }
    .stakes-container {
        margin-top: 60px;
    }
}

@media screen and (min-width: 1000px) {
    .stakes-list-left {
        padding: 30px;
        font-size: 1.3rem;
    }
    .stakes-list-right {
        font-size: 1.3rem;
        padding: 30px;
    }
    .stakes-list-items {
        padding: 40px;
    }
    .stakes-header {
        font-size: 2.2rem;
    }
}
@media screen and (max-width: 500px) {
    .stakes {
        height: 105vh;
    }
    .stakes-list-items {
       padding: 15px;
        text-align: left;
        font-size: 1rem;
        line-height: 1;
    }
    .stakes-header {
        font-size: 2rem;
    }
    .stakes-container {
        grid-template-columns: 1fr;
        margin-top: -20px;
        
    }
}