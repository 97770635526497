.stakes-bottom {
    background-color: rgb(4, 3, 46);
    padding: 2rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 25px;

    
}

@media screen and (min-width: 1000px) {
    .stakes-bottom {
        font-size: 30px;
        
    }

    
}
@media screen and (max-width: 500px) {
    .stakes-bottom {
        font-size: 20px;
        text-align: center;
    }
}