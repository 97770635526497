.extension-animation {
    font-size: 3rem;
    
    height: 100vh;
    width: 100vw;
    background: #fff;
    position: relative;
    z-index: 50; 
    display: flex;
    align-items: center;
    justify-content: center;
    /* Add animation transition here */
  }
  .word {
    font-size: 1.5rem;
    color: #081542;
  }