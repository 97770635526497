.guide-container {
    padding-top: 20px;
    position: relative;
    text-align: center;
    background-color: rgb(4, 3, 46);
}

.guide-header {
    font-size: 1.6rem;
    padding: 30px;
    border-bottom: 1px solid #fff ;
    margin-left: 10%;
    margin-right: 10%;
    color: #fff;
}

.guide-sub-header {
    padding: 30px;
    color: #fff;
    font-size: 1.1rem;
    padding-bottom: 60px;
    border-bottom: 2px solid #fff;

}

.guide-testimonials {
    background: url("/src/images/black-white.jpg")
    center center/cover no-repeat;
    z-index: -1;
    height:50vh;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
}

.guide-testimonials > h1 {
    background-color: rgb(4, 3, 46);
    color: #fff;
    font-size: 1.2rem;
    padding-top: 180px;
    text-align: center;
}
.guide-btn {
    padding-top: 20%;
    padding-left: 20%;
    padding-right: 20%;
}

.btn-header {
    margin-top: 40%;
    margin-left: 10%;
    margin-right: 10%;
    color: #fff;
    font-size: 1.3rem;
    background-color: rgb(4, 3, 46);
}

@media screen and (min-width: 960px) {
    .guide-btn {
        padding-top: 20%;
        padding-left: 20%;
        padding-right: 20%;
    }
    
    .btn-header {
        margin-top: 20%;
        margin-left: 10%;
        margin-right: 10%;
        color: #fff;
        font-size: 1.3rem;
        background-color: rgb(4, 3, 46);
    }
}

@media screen and (min-width: 1200px) {
    .guide-btn {
        padding-top: 5%;
        padding-left: 20%;
        padding-right: 20%;
        font-size: 2rem;
    }
    
    .btn-header {
        margin-top: 20%;
        margin-left: 10%;
        margin-right: 10%;
        color: #fff;
        font-size: 2rem;
        background-color: rgb(4, 3, 46);
    }
    .guide-testimonials {
        background: url("/src/images/black-white.jpg")
        center center/cover no-repeat;
        z-index: -1;
        height:60vh;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
    }
    .guide-testimonials > h1 {
        background-color: rgb(4, 3, 46);
        color: #fff;
        font-size: 1.8rem;
        padding-top: 250px;
        text-align: center;
        
    }
    .guide-header {
        font-size: 2rem;
        padding: 40px;
        border-bottom: 1px solid #fff ;
        margin-left: 10%;
        margin-right: 10%;
        color: #fff;
    }
    .guide-sub-header {
        padding: 30px;
        color: #fff;
        font-size: 1.4rem;
        padding-bottom: 70px;
    }
}
@media screen and (max-width: 500px) {
    .guide-testimonials > h1 {
        background-color: rgb(4, 3, 46);
        color: #fff;
        font-size: 1.2rem;
        padding-top: 180px;
        text-align: center;
        padding-left: 5px;
        padding-right:5px;
        height: 60vh;
    }
    .guide-testimonials {
        height: 60vh;
    }
}