.first {
    animation-name: first;
    animation-duration: 2s;
    
}
.second {
    animation-name: first;
    animation-duration: 3s;
    
}
.glitch-fix{
    animation-name: glitch-fix;
    animation-duration: 1s;
}
@media screen and (max-width: 500px) {
.zero {
    animation-name: first;
    animation-duration: 1s;
}
}
.image-container:hover .image-text {
    opacity: 1; /* Make the text visible on hover */
  }

@keyframes zero {
    0% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes first {
    0%{
        transform: translateX(100px);
        
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes glitch-fix {
    0% {
        transform: translateX(100px);
    }
    100% {
        transform: translateX(0px);
    }
}