.hero-container {
    background: url("/src/images/photo-1.jpg")
    center center/cover no-repeat;
    z-index: -1;
    
    height:100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: cover;
    color: rgb(249, 247, 245)
    
}

.hero-icon {

    font-size: 50px;
   
}

.hero-brw-construction {
    margin-top: 8px;
    color: #fff;
    font-size: 25px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-header {
    border-bottom: 2px solid #fff;
    margin-left: 30%;
    margin-right: 30%;
}

.hero-container > h1 {
    color: #fff;
    opacity: 100%;
    font-size: 60px;
    
    margin-top: -100px;
    text-align: center;
   
}

.hero-container > p {
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 40px;
        margin-top: -100px;
    }

    .hero-container > p {
        font-size: 25px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    
}