@keyframes getBig {
    from {
        filter: blur(20px);
        opacity: 0;
        transform: scale(2);
        
    }
    to {
        filter: blur(0px);
        opacity: 1;
    transform: scale(1);
    
    }
}
.blue{
    color: #081542;
    
}
.text-big {
    animation-name: getBig;
    animation-duration: 3s;
}
section {
    position: relative;
    width: 100%;
   
    display: flex;
    
   
    
    overflow: hidden;
  
}
.gap {
    padding-right: 15px;
}
.first-bit {
    margin-top: -100px;
}
h5 {
    line-height: 500px;
font-size: 3.5em;
font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    display: flex;
   overflow-y: hidden;
   margin-left: 25px;
  
}
.third-bit {
    margin-left: -400px;
}
.animation span {
    
    animation-name: getBig;
    animation-duration: 3s;
    opacity: 1;
}

h5 span:nth-child(10n+1) {
    animation-delay: 0s;
   
}
h5 span:nth-child(10n+2) {
    animation-delay: 0.05s;
}
h5 span:nth-child(10n+3) {
    animation-delay: 0.1s;
    
}
h5 span:nth-child(10n+4) {
    animation-delay: 0.15s;
  
}
h5 span:nth-child(10n+5) {
    animation-delay: 0.2s;
    
}
h5 span:nth-child(10n+6) {
    animation-delay: 0.25s;
    
}
h5 span:nth-child(10n+7) {
    animation-delay: 0.3s;
   
}
h5 span:nth-child(10n+8) {
    animation-delay: 0.35s;
    
}
h5 span:nth-child(10n+9) {
    animation-delay: 0.4s;
    
}
h5 span:nth-child(10n+10) {
    animation-delay: 0.45s;

}
h5 span:nth-child(10n+11) {
    animation-delay: 0.5s;
   
}
h5 span:nth-child(10n+12) {
    animation-delay: 0.55s;
  
}
h5 span:nth-child(10n+13) {
    animation-delay: 0.6s;
    
}
h5 span:nth-child(10n+14) {
    animation-delay: 0.65s;

}
h5 span:nth-child(10n+15) {
    animation-delay: 0.7s;
   
}
h5 span:nth-child(10n+16) {
    animation-delay: 0.75s;
 
}
h5 span:nth-child(10n+17) {
    animation-delay: 0.8s;
   
}
h5 span:nth-child(10n+18) {
    animation-delay: 0.85s;
    
}
h5 span:nth-child(10n+19) {
    animation-delay: 0.9s;
  
}
h5 span:nth-child(10n+20) {
    animation-delay: 0.95s;
 
}
h5 span:nth-child(10n+21) {
    animation-delay: 1s;

}
h5 span:nth-child(10n+22) {
    animation-delay: 1s;

}
h5 span:nth-child(10n+23) {
    animation-delay: 1s;

}
h5 span:nth-child(10n+24) {
    animation-delay: 1s;

}
h5 span:nth-child(10n+25) {
    animation-delay: 1.05s;

}
h5 span:nth-child(10n+26) {
    animation-delay: 1.1s;

}
h5 span:nth-child(10n+27) {
    animation-delay: 1.15s;

}
h5 span:nth-child(10n+28) {
    animation-delay: 1.2s;

}
h5 span:nth-child(10n+29) {
    animation-delay: 1.25s;

}
h5 span:nth-child(10n+30) {
    animation-delay: 1.3s;

}
h5 span:nth-child(10n+31) {
    animation-delay: 1.35s;

}
h5 span:nth-child(10n+32) {
    animation-delay: 1.4s;

}
h5 span:nth-child(10n+33) {
    animation-delay: 1.45s;

}
h5 span:nth-child(10n+34) {
    animation-delay: 1.5s;

}
h5 span:nth-child(10n+35) {
    animation-delay: 1.55s;

}
h5 span:nth-child(10n+36) {
    animation-delay: 1.6s;

}
h5 span:nth-child(10n+37) {
    animation-delay: 1.65s;

}
h5 span:nth-child(10n+38) {
    animation-delay: 1.7s;

}
h5 span:nth-child(10n+39) {
    animation-delay: 1.75s;

}
h5 span:nth-child(10n+40) {
    animation-delay: 1.8s;

}
h5 span:nth-child(10n+41) {
    animation-delay: 1.85s;

}
h5 span:nth-child(10n+42) {
    animation-delay: 1.9s;

}
.second-bit {
    margin-top: 100px;
}
.fourth-bit {
    margin-left: -350px;
}

@media screen and (max-width: 768px) {
    h5 {
        font-size: 1em;
    }
    .gap {
        padding-right: 15px;
    }
}
@media screen and (max-width: 500px) {
    h5 {
        font-size: 1.9em;
    }
    .gap {
        padding-right: 10px;
    }
    .fourth-bit {
        margin-left: -220px;
    }
    .third-bit {
        margin-left: -230px;
    }
    .first {
        margin-left: 0px;
    }
    .section {
        min-width: 100vw;
        margin-top: -150px;
        /*display: none;*/
    }
    .second-bit {
        margin-top: 50px;
    }
    .first-bit {
        margin-top: -50px;
    }
}
