.img {
    height: 100%;
    width: 40vw;
    object-fit: cover;
    max-height: 400px;
    
}
/* .marg {
    height: 100%;
    width:90%;
    padding-top: 25px;
    display: flex;
    
    
    flex-direction: column;
} */
.cont {
    overflow:hidden;
     display: flex;
      justify-content:center;
       align-items:center;
       margin-top: 100px;
}
.box {
    width: 40vw;
     height: 100%;
     position: relative;
}
.img-box {
    width: 500vw;
     height: 100%; display: flex; transition: transform; transition-duration: 1000ms; 
}
.arw-wrap {
    width: 90%;  display: flex; justify-content: right; gap: 10px; 
    
}
.arrows-l {
    
}
.arrows-r {
    
}
.left-arw {
    width: 3rem;;background: #000; color: #fff; height: 3.5rem; border: 0.5px solid #fff; display: flex; align-items: center; justify-content: center; transition-duration: 300ms; 
}
.right-arw {
    width: 3rem; color: #fff;background: #000; height: 3.5rem; border: 0.5px solid #fff; display: flex; align-items: center; justify-content: center; transition-duration: 300ms; 
}
.left-arw:hover{
    cursor:pointer; background:#333; color: white; 
}
.left-arw:active{
    background: gray;
}
.right-arw:hover{
    cursor:pointer; background:#333; color: white; 
}
.right-arw:active{
    background: gray;
}
.head {
    animation-name: up;
    animation-duration: 1500ms;
    
    opacity: 1;
}
@keyframes up {
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
.opac0 {
    opacity: 0;;
}
@media screen and (max-width: 900px) {
    .img {
        width: 90vw;
    }
    .box {
        width: 90vw;
        height: 400px;
    }
    .cont {
        margin-top: 50px;
    }
}
@media screen and (max-width: 500px) {
    .img {
        width: 90vw;
    }
    .box {
        width: 90vw;
        height: 250px;
    }
    .cont {
        margin-top: 50px;
    }
}

