.animation {
    animation-name: animation;
    animation-duration: 3s;
}

@keyframes animation {
    0% {
        padding: 75px;
    }
    100% {
        padding-left: 150px;
    }
}
.animation2 {
    animation-name: animation2;
    animation-duration: 4s;
    transition: 3ms ease;
    transform: translateX(-50px);
}

@keyframes animation2 {
    0% {
        color: transparent;
    transform: translateX(50px);
    }
    100% {
        color: black;
        transform: translateX(-50px);
    }
}
.animation3 {
    animation-name: animation3;
    animation-duration: 4s;
    transition: 3ms ease;
    transform: translateX(-50px);
    color: #081542;
}

@keyframes animation3 {
    0% {
        color: transparent;
    transform: translateX(50px);
    }
    100% {
        color: #081542;
        
        transform: translateX(-50px);
    }
}
.animation4 {
    animation-name: animation4;
    animation-duration: 2s;
    animation-delay: 0s;
    
    /*transform: translateX(-150px);*/
}
@keyframes animation4 {
    0% {
        background-color: transparent;
        
    }
    100% {
        background-color: #081542;
    }
}
.hovered {
    width: 150px;
}
.not-hovered{
    width: 50px;
}
@media screen and (max-width: 1100px) {
    .not-hovered {
        width: 100px;
    }
}
@media screen and (max-width: 768px) {
    .not-hovered {
        width: 50px;
    }
}
