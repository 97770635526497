@keyframes animate {
    0% {
        font-size: 2rem;
    }
    100% {
        font-size: 5rem;
    }
}
.oy {
    overflow-y: hidden;
}
.fa-chevron-down  {
    font-size: 4rem;
   
    animation: slide-in 2s ease infinite;
}
@keyframes slide-in {
    0% {
        transform: translateY(-7.5px);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-7.5px)
    }
}

@media screen and (max-width: 768px) {
    .container-hero {
        animation: scale 2000ms;
    }
}
@keyframes scale {
    0% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}