.contact-sub-header1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 25px;
}
.contact1 {
    margin-top: 50px;
    
}
.input-box1 {
    width: 500px;
    height: 50px;
}
.input-box2 {
    width: 500px;
    height: 100px;
}
.contact-input-items1 {
    margin-bottom: 10px;
}
@media screen and (max-width: 500px) {
    .input-box1 {
        width: 300px;
    }
    .input-box2 {
        width: 300px;
    }
}