.all {
    border-bottom: 2px solid #081542 ;
    
    margin-top: 4px;
}

.fade-in {
    animation: fade-in 500ms ease-in-out;
  }
  @keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  .fade-in-2 {
    animation: fade-in-2 200ms ease-in-out;
  }
  @keyframes fade-in-2 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }