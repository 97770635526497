.explanatory {
    margin: 50px;
    padding: 30px;
    background-color: rgb(4, 3, 46);
    color: #fff;
    font-size: 1.2rem;
}

@media screen and (min-width: 1200px) {
    .explanatory {
        margin: 50px;
        padding: 30px;
        background-color: rgb(4, 3, 46);
        color: #fff;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 500px) {
    .explanatory {
        margin: 20px;
        padding: 20px;
        background-color: rgb(4, 3, 46);
        color: #fff;
        font-size: 1.2rem;
    }
}