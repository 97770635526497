.side1header {
    display: inline-block;
    font-size: 2.5rem;
    font-family: 'Roboto Slab', serif;
    overflow: hidden;
}
.null {
    display: none;
    color: rgb(0, 96, 174)
}
.animationHeader {
    animation-duration: 1.5s;
    animation-name: animationHeader;
    overflow: hidden;
}
.animationHeader2 {
    animation-duration: 1.5s;
    animation-name: animationHeader2;
    overflow: hidden;
}
@keyframes animationHeader{
    0% {
        transform: translateX(-200px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes animationHeader2{
    0% {
        transform: translateX(200px);
    }
    100% {
        transform: translateX(0px);
    }
}
@media screen and (max-width: 1200px) {
    .side1header {
        font-size: 2.5rem;
    }
}
@media screen and (max-width: 1050px) {
    .side1header {
        font-size: 2.5rem;
    }
}
@media screen and (max-width: 768px) {
    .side1header {
        font-size: 2.5rem;
    }
    .btn{
        width: 200px;
        padding: 15px;
        padding-left: 15px;
        padding-right: 15px;
    
    }
}
@media screen and (max-width: 500px) {
    .side1header {
        font-size: 2rem;
    }
}