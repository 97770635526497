.arrow {
    /* display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 1.25rem; */
   
   /* font-size: 0.8rem; */
   color: #081452;
   font-size: 0.8rem;
}
.oy {
    overflow-y: hidden;
}
.arrow:hover {
    
}
.circle {
    /* color: #081452; */
    
    font-size: 2.2rem;
    margin-top: 10px;

}
.circle:hover {
    /* color: #081452; */
    transition-duration: 300ms;
}

.circle-container {
    height: 10vh;
   margin-top: 80px; 
   position: relative;
   top:50%;
   right: 0;
   color: rgb(220,220,220);
}
.circle-container:hover {
    color: #081452;
    transition-duration: 300ms;;
    
}
.css-circle{
    width: 40px;
  height: 40px;
  border: 2px solid rgb(220,220,220);; /* Border thickness */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.css-circle:hover {
    border: 2px solid #081452;
    padding-left: 7.5px;
    transition: ease;
    transition-duration: 300ms;
}
.css-circle2{
    width: 40px;
  height: 40px;
  border: 2px solid rgb(220,220,220);; /* Border thickness */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.css-circle2:hover {
    border: 2px solid #081452;
    padding-right: 7.5px;
    transition: ease;
    transition-duration: 300ms;
}
.arrows-wrap {
    display: flex;
    gap: 10px;
    margin-top: 25px;
    margin-bottom: 15px;
}

.anim-on-scroll {
    animation-name: anim-on-scroll;
    transition: ease;
    animation-duration: 1500ms;

}

@keyframes anim-on-scroll {
    0% {
        transform: translateX(-60vw);
    }
    100% {
        transform: translateX(0vw);
    }
}
.anim-line {
    animation-name: anim-line;
    transition: ease;
    animation-duration: 2000ms;
    
}
@keyframes anim-line {
    0% {
        height: 0px;
    }
    100% {
        height: 200px;
    }
}
@media screen and (max-width: 768px) {
    .anim-line {
        animation-name: anim-line2;
        transition: ease;
        animation-duration: 2000ms;
        
    }
    @keyframes anim-line2 {
        0% {
            height: 0px;
        }
        100% {
            height: 275px;
        }
    }
}