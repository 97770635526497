.plan {
    height: 80vh;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #f9f9f9;
    padding-bottom: 4rem;
    color: rgb(9, 6, 98);
    
    position: relative;
    min-height: 50px;
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}


.plan-container {
    font-size: 1.2rem;
    padding-right: 5px;
}

.plan-p {
    padding: 30px;
    padding-bottom: 20px;
}

.plan-header {
    font-size: 1.88rem;
    padding-bottom: 15px;
    
}

.fa-list-ol {
    align-self: center;
    margin-left: 18%;
    font-size: 3.5rem;
    color: rgb(10, 216, 10);
    padding: 30px;
 
    
}

.fa-phone2 {
    align-self: center;
    margin-left: 22%;
    font-size: 3.5rem;
    color: rgb(10, 216, 10);
    padding: 30px;
    
    
}

.fa-check-double {
    align-self: center;
    margin-left: 22%;
    font-size: 3.5rem;
    color: rgb(10, 216, 10);
    padding: 30px;
   
    
}

.plan-btn {
    padding-top: 100px;
    margin-left: -500px;
}

@media screen and (min-width: 1000px) {
    .fa-phone2 {
        align-self: center;
        margin-left: 39%;
        font-size: 4rem;
        color: rgb(10, 216, 10);
        padding: 30px;
    }
    .fa-list-ol {
        align-self: center;
        margin-left: 38%;
        font-size: 4rem;
        color: rgb(10, 216, 10);
        padding: 30px;
        padding-bottom: 30px;
    }
    .fa-check-double {
        align-self: center;
        margin-left: 38%;
        font-size: 4rem;
        color: rgb(10, 216, 10);
        padding: 30px;
        padding-bottom: 30px;
    }
    .value-btn {
        margin-left: 35%;
    }
    .plan-p {
        margin-left: 7%;
    }
    .plan-btn {
        margin-left: -365px;
    }
}

@media screen and (max-width: 500px) {
    .fa-phone2 {
        align-self: center;
        margin-left: 35%;
        font-size: 3rem;
        color: rgb(10, 216, 10);
        padding: 30px;
    }
    .fa-list-ol {
        align-self: center;
        margin-left: 35%;
        font-size: 3rem;
        color: rgb(10, 216, 10);
        padding: 30px;
        padding-bottom: 30px;
    }
    .fa-check-double {
        align-self: center;
        margin-left: 35%;
        font-size: 3rem;
        color: rgb(10, 216, 10);
        padding: 30px;
        padding-bottom: 20px;
    }
    .value-btn {
        margin-left: 0%;
    }
    .plan-p {
        margin-left: 0%;
    }
    .plan-btn {
        margin-left: 0%;
    }
    .plan-container {
        font-size: 1.2rem;
        padding-right: 0px;
    }
    
    .plan-p {
        padding: 0px;
        padding-left: 10px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
    
    .plan-header {
        font-size: 1.5rem;
        padding-bottom: 15px;
    }
    .plan {
        padding-left: 0rem;
        padding-right: 0rem;
        grid-template-columns: 1fr;
    }
}