.stakes {
    height: 50vh;
    
}
.gap {
    margin-top: 50px;
}
.stakes-header {
    color: #081542;
    font-size: 2.5rem;
    border: none;
    font-family: 'Poppins', sans-serif;
}
.offer-list {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}
.animationHeader {
    animation-duration: 2s;
    animation-name: animationHeader;
    color: #081542;
    overflow-y: hidden;
}
.animationHeader2 {
    animation-duration: 3s;
    animation-name: animationHeader;
    color: #081542;
    overflow: auto;
}
@keyframes animationHeader{
    0% {
       
        transform: translateY(200px);
        overflow-y: hidden;
        overflow-x: auto;
        
    }
    100% {
        
        transform: translateY(0px);
        overflow-x: auto;
        
    }
}
.side1header {
    display: inline-block;

    font-size: 2.5rem;
    
    
    
    text-align: center;
    overflow: auto;
}

.text-box{
    position: absolute;
    bottom: 38%;
    left: 8%;;
  text-align: center;
    height: 7%;
    width: 100%;
    color: #000;
    display: flex;
    background-color: #fff;
    font-size: 25px;
    font-weight: 900;
    mix-blend-mode: screen;
    z-index: 3;
}

.video {
    width: 20%;
    height:5%;
    object-fit: cover;
    position: absolute;
    bottom: 40%;
    left: 27%;
}
.arrow {
    margin-left: 2px;
    margin-top: 2px;
}


@media screen and (max-width: 960px) {
    .stakes-list-left {
        margin-left: 5%;
        font-size: 18px;
        color: #fff;
        text-align: left;
    }
    .stakes-list-right {
        margin-left: 5%;
        font-size: 18px;
        color: #fff;
    }
    .stakes-list-items {
        font-size: 1rem;
        line-height: 2;
    }
}

@media screen and (max-width: 3000px) {
    
}
@media screen and (max-width: 900px) {
    .stakes {
        height: 140vh;
    }
}
@media screen and (max-width: 768px) {
    .stakes {
        height: 200vh;
        
    }
    .text-box {
        overflow-y: hidden;
        bottom: 31%;
        left: 2%;
        mix-blend-mode: normal;
        background: transparent;
        font-size: 1.4rem;
    }
    .video{
        overflow-y: hidden;
        display: none;
    }
    .stakes-list-left {
        margin-left: 5%;
        font-size: 18px;
        color: rgb(8, 6, 56);
    }
    .stakes-list-right {
        margin-left: 5%;
        font-size: 18px;
        color: rgb(8, 6, 56);
        padding: 50px;
    }
    .stakes-list-items {
        padding: 20px;
    }
    .stakes-container {
        margin-top: 60px;
    }
}


@media screen and (min-width: 1000px) {
    
}
@media screen and (max-width: 500px) {
    .stakes {
        height: 80vh;
    }
    .text-box {
        bottom: 28%;
    }
    .stakes-list-items {
       padding: 15px;
        text-align: left;
        font-size: 1rem;
        line-height: 1;
    }
    .stakes-header {
        font-size: 1.4rem;
    }
    .stakes-container {
        grid-template-columns: 1fr;
        margin-top: -20px;
        
    }
}
@media screen and (max-width: 400px) {
    .text-box {
        bottom: 28%;
    }
}
@media screen and (max-width: 380px) {
    .text-box {
        bottom: 31%;
    }
    .stakes {
        height: 80vh;
        
    }
}