.header-plan {
    padding-top: 150px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 2.2rem;
    color: rgb(9, 6, 98);
    border-bottom: 2px solid rgb(9, 6, 98);
    margin-left: 5%;
    margin-right: 5%;
    background-color: #f9f9f9;
}