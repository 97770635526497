
.one {
    animation-name: one;
    animation-duration: 1s;
}
@keyframes one {
    0% {
        margin-left: -1000px;
    }
    100% {
        margin-left: 0px;
    }
}
.one-gone {
    animation-name: one-gone;
    animation-duration: 1s;
}
@keyframes one-gone {
    0% {
        margin-left: 0px;
    }
    100% {
        margin-left: -1000px;
    }
}

.two {
    animation-name: two;
    animation-duration: 1s;
}
@keyframes two {
    0% {
        margin-left: 1000px;
    }
    100% {
        margin-left: 0px;
    }
}
.two-gone {
    animation-name: two-gone;
    animation-duration: 1s;
}
@keyframes two-gone {
    0% {
        margin-left: 0px;
    }
    100% {
        margin-left: 1000px;
    }
}
@media screen and (max-width: 600px) {
    .one{
        animation-name: one2;
        animation-duration: 1s;
    }
    .two {
        animation-name: two2;
        animation-duration: 1s;
    }
    
}
@keyframes one2 {
    0% {
        margin-left: -200px;
    }
    100% {
        margin-left: 0px;
    }
}
@keyframes two2 {
    0% {
        margin-left: 200px;
    }
    100% {
        margin-left: 0px;
    }
}