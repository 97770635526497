.navbar {
    /* background:  rgb(255, 255, 255, 0.8);
    background: linear-gradient(#fff 0%,  transparent 100%);
     */
    height: 120px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100vw;
    position: fixed;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: rgb(9, 6, 98);
    justify-self: start;
    
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-top: 0px;
    position: absolute;
    
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
    max-height: 70vh;
  }
  .phone {
    display: none;
    
  }
  .contact-us {
    transition: 300ms ease-in-out;
  }
  .phone:hover{
    color: rgb(39, 153, 191);
    transition: ease;
    transition-duration:  500ms ;
  }
  
  .contact-us:hover{
    /* color: rgb(39, 153, 191); */
    transform: translateY(-5px);
    
  }

  .nav-item {
    
    color: rgb(9, 6, 98);
  }
  
  .nav-links {
    color: rgb(9, 6, 98);
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid rgb(9, 6, 98);
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: rgb(9, 6, 98);
  }
  
  .nav-links-mobile {
    display: none;
  }
 
  .menu-icon {
    display: block;
    position: absolute;
    top: 30%;
    left: 10%;
    
    font-size: 2rem;
    cursor: pointer;
  }

  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  .phone {
    display: inline;
    position: absolute;
    right: 5%;
    top: 37.5%;
    font-size: 1.5rem;
    color: #081542;
  }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #fff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      margin-top: 0px;
      
      /*transform: translate(25%, 50%);*/
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 30%;
      left: 5%;
      
      font-size: 2rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: rgb(9, 6, 98);
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 28%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: rgb(9, 6, 98);
      color: #fff;
      padding: 16px 20px;
      border: 1px solid rgb(9, 6, 98);
      transition: all 0.3s ease-out;
      width: 80%;

      @media screen and (max-width: 460px){
        width: 100%;
        color: red;
      }
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }

  .brw-logo {
    max-width: 150px;
    height: 100%;
    margin-top: 0px;
    
  }
  .btn-wrap {
    position: absolute;
    right: 0;
    top: 30%;
    right: 5%;

  }
  .contact-us {
    text-decoration: none !important;
    color: #081542;
    
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
  }
  .phone-nav {
    margin-right: 10px;
    margin-top: -15px;
    color: #081542;
    
    font-size: 1.2rem;
  }
  @media screen and (max-width: 1100px) {
    
  }
  @media screen and (max-width: 500px) {
    .navbar {
      height: 100px;
    }
    .navbar-container {
      height: 80px;
    }
    .brw-logo {
      margin-top: 10px;
    }
    .btn-wrap {
      margin-right: 85px;
    }
  }
