.value {
    border-bottom: 4px solid rgb(9, 6, 98);
    padding: 4rem;
    color: rgb(9, 6, 98);
    height: 100vh;
    background-color: #f9f9f9;
    position: relative;
    min-height: 50px;
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.header {
    padding-top: 130px;
    background-color: #f9f9f9;
    font-size: 3rem;
}

.value-container {
    font-size: 1.2rem;
}

.value-p {
    padding: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(9, 6, 98);
    
}

.value-header {
    font-size: 1.5rem;
    border-bottom: 1px solid rgb(9, 6, 98);
    padding-bottom: 15px;
}

.fa-couch {
    align-self: center;
    margin-left: 0%;
    font-size: 6rem;
    color: rgb(9, 6, 98);
    padding: 30px;
    padding-bottom: 61px;
}

.fa-circle-check {
    align-self: center;
    margin-left: 6%;
    font-size: 6rem;
    color: green;
    padding: 30px;
}

.fa-business-time {
    align-self: center;
    margin-left: 6%;
    font-size: 6rem;
    color: rgb(10, 216, 10);
    padding: 30px;
    padding-bottom: 61px;
}

.value-btn {
    padding-top: 50px;
}

@media screen and (min-width: 1000px) {
    .fa-circle-check {
        align-self: center;
        margin-left: 32%;
        font-size: 9rem;
        color: green;
        padding: 30px;
    }
    .fa-couch {
        align-self: center;
        margin-left: 29%;
        font-size: 9rem;
        color: rgb(9, 6, 98);
        padding: 30px;
        padding-bottom: 30px;
    }
    .fa-business-time {
        align-self: center;
        margin-left: 29%;
        font-size: 9rem;
        color: rgb(10, 216, 10);
        padding: 30px;
        padding-bottom: 30px;
    }
    .value-btn {
        margin-left: 35%;
    }
    
}

@media screen and (max-width: 500px) {
    
    .fa-circle-check {
        align-self: center;
        margin-left: 34%;
        font-size: 4rem;
        color: green;
        padding: 20px;
    }
    .fa-couch {
        align-self: center;
        margin-left: 28%;
        font-size: 4rem;
        color: rgb(9, 6, 98);
        padding: 30px;
        padding-bottom: 30px;
    }
    .fa-business-time {
        align-self: center;
        margin-left: 31%;
        font-size: 4rem;
        color: rgb(10, 216, 10);
        padding: 30px;
        padding-bottom: 30px;
    }
    .value-btn {
        margin-left: 0%;
    }
    .value {
        border-bottom: 4px solid rgb(9, 6, 98);
        padding: 2rem;
        color: rgb(9, 6, 98);
        
        position: relative;
        min-height: 50px;
        font-size: 5px;
        display: grid;
        grid-template-columns: 1fr;
    }
    .header {
        padding-top: 30px;
        
        font-size: 2rem;
    }
    
    .value-container {
        font-size: 1rem;
    }
    
    .value-p {
        padding: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(9, 6, 98);
        
    }
    
    .value-header {
        font-size: 1.2rem;
        border-bottom: 1px solid rgb(9, 6, 98);
        padding-bottom: 10px;
    }
}