.video-container {
    padding: 30px;
}

.video-header {
    color: rgb(9,6,98);
    font-family: sans-serif;
    
}

@media screen and (max-width: 960px) {
    .ratio {
        margin-left: 12%;
        padding-top: 50px;
    }
}
@media screen and (min-width: 1000px) {
    .ratio {
        margin-left: 12%;
        padding-top: 50px;
    }
}

@media screen and (max-width: 750px) {
    .ratio {
        margin-left: 5%;
    }
}