/* MenuIcon.css */
.menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: absolute;
    z-index: 999;
  }
  
  .bar {
    width: 30px;
    height: 4px;
    background-color: #081542;
    margin: 4px 0;
    transition: transform 0.3s ease;
  }
  
.bar2 {
  width: 22.5px;
  transition: 200ms ease-in-out;
}
.bar-hovered {
  width: 30px;
}
  .open .bar:nth-child(1) {
    width: 27px;
    transform: rotate(-45deg) translate(-2.5px, 6px);
  }
  
  .open .bar:nth-child(2) {
    width: 27px;
    transform: rotate(45deg) translate(-1px, -6px);
  }
  
  .open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
    display: none;
    
 
  }