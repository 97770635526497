.how {
    background: #081542;
}
.not-how {
    background: transparent;
}
.bold {
    color: rgb(202, 210, 255);
    color: #fff;
    font-weight: 400;
}