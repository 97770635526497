:root {
   
}

.btn2 {
    padding: 8px 20px;
    padding-left: 0px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    color: #071023;
    font-weight: 600;
     background: #fff;
     background: #081542;
     color: #fff;
}



.btn--outline1 {
    background-color: transparent;
    color: rgb(248, 247, 251);

    padding: 8px 20px;
    padding-left: 0px;
    border: 1px solid rgb(248, 247, 251);
    transition: all 0.3s ease-out;
    min-width: 250px;
}

.btn--outline-nav {
    background-color: transparent;
    color: rgb(9, 6, 98);
    padding: 8px 20px;
    border: 1px solid rgb(248, 247, 251);
    transition: all 0.3s ease-out;
}
.btn--guide1 {
    background-color: rgb(4, 3, 46);
    color: #fff;
    padding: 8px 20px;
    padding-left: 0px;
    border: 1px solid rgb(248, 247, 251);
    transition: all 0.3s ease-out;
}
.btn--medium1 {
    padding: 8px 20px;
    padding-left: 0px;
    font-size: 20px;
    max-width: 220px;
}

.btn--large1 {
    padding: 12px 26px;
    padding-left: 0px;
    font-size: 20px;
    min-width: 400px;
}

.btn--medium:hover, .btn--large:hover {
    background-color: rgb(250, 251, 252);
    color: #242424;
    transition: all 0.3s ease-out;
}