
.image-container-clean:hover .image-text {
    opacity: 1; /* Make the text visible on hover */
  }
  
.oy {
    overflow-y: hidden;
}
@keyframes zero {
    0% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes first {
    0%{
        transform: translateX(100px);
        display: none;
    }
    100% {
        transform: translateX(0px);
    }
}
.img-txt-hov-clean {
    animation-name: img-txt-hov-clean;
    animation-duration: 300ms;
    
}
@keyframes img-txt-hov-clean {
    0% {
        font-size: 1.5rem;
    }
    100% {
        font-size: 1.2rem;
    }
}
.img-txt-hov-no-clean {
    animation-name: img-txt-hov-no-clean;
    animation-duration: 300ms;
    
}
@keyframes img-txt-hov-no-clean {
    0% {
        font-size: 1.2rem;
    }
    100% {
        font-size: 1.5rem;
    }
}
.img-txt-hov2-clean {
    animation-name: img-txt-hov2-clean;
    animation-duration: 300ms;
    
}
@keyframes img-txt-hov2-clean {
    0% {
        font-size: 3.5rem;
    }
    100% {
        font-size: 1rem;
    }
}
.img-txt-hov-no2-clean {
    animation-name: img-txt-hov-no2;
    animation-duration: 300ms;
    
}
@keyframes img-txt-hov-no2-clean {
    0% {
        font-size: 1rem;
    }
    100% {
        font-size: 2rem;
    }
}
.blurry-image {
    position: relative;
    filter: blur(10px); /* Apply a blur filter initially */
    transition: filter 1s; /* Add a smooth transition */
  }
  
  .blurry-image.loaded img {
    filter: blur(0); /* Remove the blur filter when loaded and in view */
  }