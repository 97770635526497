.first {
    animation-name: first;
    animation-duration: 2s;
    
}
.second {
    animation-name: first;
    animation-duration: 3s;
    
}
.zero {
    animation-name: first;
    animation-duration: 1s;
}
.image-container:hover .image-text {
    opacity: 1; /* Make the text visible on hover */
  }
  

@keyframes zero {
    0% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes first {
    0%{
        transform: translateX(100px);
        display: none;
    }
    100% {
        transform: translateX(0px);
    }
}
.img-txt-hov {
    animation-name: img-txt-hov;
    animation-duration: 300ms;
    
}
@keyframes img-txt-hov {
    0% {
        font-size: 3.5rem;
    }
    100% {
        font-size: 1.2rem;
    }
}
.img-txt-hov-no {
    animation-name: img-txt-hov-no;
    animation-duration: 300ms;
    
}
@keyframes img-txt-hov-no {
    0% {
        font-size: 1.2rem;
    }
    100% {
        font-size: 2rem;
    }
}
.img-txt-hov2 {
    animation-name: img-txt-hov2;
    animation-duration: 300ms;
    
}
@keyframes img-txt-hov2 {
    0% {
        font-size: 3.5rem;
    }
    100% {
        font-size: 1rem;
    }
}
.img-txt-hov-no2 {
    animation-name: img-txt-hov-no2;
    animation-duration: 300ms;
    
}
@keyframes img-txt-hov-no2 {
    0% {
        font-size: 1rem;
    }
    100% {
        font-size: 2rem;
    }
}
#card {
    width: 400px;
    height: 350px;
    /* border: 0.25rem solid #333; */
    /* margin: 50px; */
   overflow: hidden;
    
}



.img-fuzz {
    height: 100%;
    
    width: 100%;
    object-fit: cover;
    
}
 .img-fuzz {
    filter: url(#noise); /* Apply the fuzzy effect */
  }
  
  /* Apply the scale effect on hover */
  .img-fuzz:hover {
    animation: pulse 800ms ease-in-out;
  }
.svg-fuzz {
    display: none;
}
@keyframes pulse {
    from, to {
        scale:1;
    }

    50% {
        scale: 1.08;
    }
}