.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.canvas {
    font-style: italic;
}

.btn-hero {
    max-width: 20px;
    margin-left: 200px;
}

.slide-active {
    opacity: 1;
    transition-duration: 1s;
    color: rgb(26, 34, 194);
}

.header {
    transform: translateY(-2px);
}

.header-active {
    transform: translateY(2px);
    transition-duration: 2s;
}